<template>
    <div class="grant-files" :key="dynamicKey">
        <Breadcrumbs
            :items="items"
        />

        <h1 class="grant-files_title" v-if="scholarshipName">
            {{ scholarshipName }} {{ scholarshipYear }} Documents du dossier de bourse
        </h1>

        <form action="" @submit.prevent="saveDocuments" v-if="scholarshipName">
            <div v-for="(item, i) in grantFiles" :key="i">
                <div>
                    <div class="form-field">
                        <p>{{ item.name }}<span class="required">*</span></p>
                        <md-field :class="{'uploaded': form[item.field]}">
                            <md-file
                                @change="changeHandler(item.field, item.statusField, i)"
                                placeholder="Sélectionner un fichier"
                                :required="!item.file"
                                :value="item.file"
                                accept="application/pdf"
                            ></md-file>
                        </md-field>
                        <div class="document-status">
                            <div>
                                <div v-html="svgApproved(item.field)"></div>
                                <md-tooltip md-direction="right">
                                    <p>Accepté</p>
                                </md-tooltip>
                            </div>
                            <div>
                                <div v-html="svgDeclined(item.field)"></div>
                                <md-tooltip md-direction="right">
                                    <p>Refusé</p>
                                </md-tooltip>
                            </div>
                        </div>
                    </div>
                    <div v-if="item.error" class="form-field">
                        <p></p>
                        <span class="file-error-msg">{{ fileErrorMsg }}</span>
                    </div>
                </div>
                <div>
                    <div class="form-field">
                        <p></p>
                        <md-field>
                            <md-input v-model="item.comment" :placeholder="$t('conventionProcessLetterComment')"/>
                        </md-field>
                    </div>
                </div>
            </div>

            <div class="form-field">
                <p></p>
                <md-button type="submit" class="next">
                    <span>Sauvegarder</span>
                    <img src="@/assets/images/arrow-right.svg" alt="next"/>
                </md-button>
            </div>
        </form>

    </div>
</template>

<script>
import Breadcrumbs from '@/components/breadcrumbs'
import {mapActions, mapGetters} from "vuex";

export default {
    name: "GrantFiles",

    components: {
        Breadcrumbs
    },

    data: () => ({
        dynamicKey: 1,
        items: [
            {
                text: 'home',
                href: '/'
            },
            {
                text: 'grantFiles',
                href: '#',
            }
        ],
        fileErrorMsg: 'Merci de convertir votre fichier au format pdf avant de le télécharger',
        grantFiles: [
            {
                name: 'Formulaire Campus France',
                field: 'campusFranceform',
                status: '',
                comment: '',
                statusField: 'campusFranceformStatusId',
                error: false
            },
            {
                name: 'Formulaire Ambassade',
                field: 'embassyform',
                status: '',
                comment: '',
                statusField: 'embassyformStatusId',
                error: false
            },
            {
                name: 'Copie du passeport international valide',
                field: 'passportcopy',
                status: '',
                comment: '',
                statusField: 'passportcopyStatusId',
                error: false
            },
            {
                name: 'Lettre d’admission',
                field: 'admissionletter',
                status: '',
                comment: '',
                statusField: 'admissionletterStatusId',
                error: false
            },
        ],
        form: {
            id: '',
            campusFranceform: '',
            campusFranceformStatusId: '',
            campusFranceformComment: '',

            embassyform: '',
            embassyformStatusId: '',
            embassyformComment: '',

            passportcopy: '',
            passportcopyStatusId: '',
            passportcopyComment: '',

            admissionletter: '',
            admissionletterStatusId: '',
            admissionletterComment: '',
        },
    }),

    computed: {
        ...mapGetters('applications', ['scholarships', 'projectData', 'applicationProjects']),
        scholarship() {
            return this.scholarships?.find(el => el.applicationId !== null)
        },
        scholarshipName() {
            let name = ''
            switch (this.scholarship?.scholarshipTypeId || +this.$route.params.id) {
                case 1:
                    name = 'Metchnikov'
                    break;
                case 2:
                    name = 'Ostrogradski'
                    break;
                case 3:
                    name = 'Vernadski'
                    break;
                case 4:
                    name = 'Master'
                    break;
            }
            return name
        },
        scholarshipYear() {
            return this.$moment(this.scholarship?.closingDate).format('YYYY')
        },
    },

    watch: {
        scholarship: {
            immediate: true,
            async handler(value) {
                if (value && value.id && !this.projectData) {
                    const data = await this.getApplicationProjects(value.applicationId)
                    const currentData = data[0]
                    for (let field in this.form) {
                        if (currentData[field] !== "null") {
                            this.form[field] = currentData[field]
                        }
                    }
                    this.formattedFiles()
                }
            }
        }
    },

    async mounted() {
      if (this.scholarship) {
        const data = await this.getApplicationProjects(this.scholarship.applicationId)
        const currentData = data[0]
        this.dynamicKey++
        for (let field in this.form) {
          if (currentData[field] !== "null") {
            this.form[field] = currentData[field]
          }
        }
      }
      
        if (this.projectData) {
            for (let field in this.form) {
                if (this.projectData[field] !== "null") {
                    this.form[field] = this.projectData[field]
                }
            }
            this.formattedFiles()
        }
    },

    methods: {
        ...mapActions('applications', ['getApplicationProjects', 'getProject']),
        formattedFiles() {
            this.grantFiles.map(item => {
                item.file = this.form[item.field]?.replace('Files/', ' ');
                item.comment = this.form[item.field + 'Comment'];
            })
        },
        svgApproved(field) {
            return (this.form[field] === 2) ? `<svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.879 0.578766C20.5132 1.07185 20.6277 1.98568 20.1346 2.61988L8.82556 17.1653C8.5737 17.4892 8.19676 17.6918 7.78761 17.7229C7.37847 17.7541 6.97521 17.6109 6.67725 17.3288L0.531697 11.5106C-0.0516634 10.9583 -0.0768557 10.0377 0.475429 9.45435C1.02771 8.87099 1.94833 8.8458 2.53169 9.39808L7.51302 14.114L17.8379 0.834273C18.331 0.20008 19.2449 0.0856857 19.879 0.578766Z" fill="#0045FF"/>
                </svg>`
                :
                `<svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.879 0.578766C20.5132 1.07185 20.6277 1.98568 20.1346 2.61988L8.82556 17.1653C8.5737 17.4892 8.19676 17.6918 7.78761 17.7229C7.37847 17.7541 6.97521 17.6109 6.67725 17.3288L0.531697 11.5106C-0.0516634 10.9583 -0.0768557 10.0377 0.475429 9.45435C1.02771 8.87099 1.94833 8.8458 2.53169 9.39808L7.51302 14.114L17.8379 0.834273C18.331 0.20008 19.2449 0.0856857 19.879 0.578766Z" fill="#CDD0D9"/>
                </svg>`
        },
        svgDeclined(field) {
            return (this.form[field] === 3) ? `<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9951 17.5362C16.4921 18.0332 17.298 18.0332 17.795 17.5362C18.292 17.0392 18.292 16.2333 17.795 15.7363L11.0586 8.99992L17.795 2.26352C18.292 1.76648 18.292 0.960639 17.795 0.463608C17.298 -0.0334225 16.4921 -0.0334225 15.9951 0.463608L9.25871 7.20002L2.52229 0.463594C2.02526 -0.0334377 1.21941 -0.0334377 0.722384 0.463594C0.225352 0.960624 0.225352 1.76647 0.722384 2.26351L7.45881 8.99992L0.722382 15.7363C0.225352 16.2333 0.225352 17.0392 0.722382 17.5362C1.21941 18.0333 2.02526 18.0333 2.52229 17.5362L9.25871 10.7998L15.9951 17.5362Z" fill="#FF4646"/>
                </svg>`
                :
                `<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9951 17.5362C16.4921 18.0332 17.298 18.0332 17.795 17.5362C18.292 17.0392 18.292 16.2333 17.795 15.7363L11.0586 8.99992L17.795 2.26352C18.292 1.76648 18.292 0.960639 17.795 0.463608C17.298 -0.0334225 16.4921 -0.0334225 15.9951 0.463608L9.25871 7.20002L2.52229 0.463594C2.02526 -0.0334377 1.21941 -0.0334377 0.722384 0.463594C0.225352 0.960624 0.225352 1.76647 0.722384 2.26351L7.45881 8.99992L0.722382 15.7363C0.225352 16.2333 0.225352 17.0392 0.722382 17.5362C1.21941 18.0333 2.02526 18.0333 2.52229 17.5362L9.25871 10.7998L15.9951 17.5362Z" fill="#CDD0D9"/>
                </svg>`
        },
        changeHandler(field, status, i) {
            if (event.target.files[0].type !== 'application/pdf') {
                this.grantFiles[i].error = true
                this.form[field + 'StatusId'] = '';
                this.form[field] = '';
                return;
            }
            this.form[field + 'StatusId'] = 1;
            this.form[field] = event.target.files[0];
            this.grantFiles[i].error = false;
        },
        saveDocuments() {
//            this.grantFiles.forEach(item => {
//                this.form[item.field + 'Comment'] = item.comment
//            })
//
//            const formData = new FormData();
//            for (let field in this.form) {
//                formData.append(field, this.form[field]);
//            }
//            this.$axios
//                .post(process.env.VUE_APP_API_URL + '/AccountProject/EditGrandFiles', formData, {
//                    headers: {
//                        'Content-Type': 'multipart/form-data',
//                    }
//                }).then(() => {
//                    this.$router.push({name: 'application'})
//                })
        }
    }
}
</script>

<style lang="scss" scoped>
.grant-files {
    &_title {
        font-weight: 900;
        font-size: 36px;
        line-height: 52px;
        margin-bottom: 40px;
        @media (max-width: 1024px) {
            font-size: 22px;
            line-height: 34px;
        }
    }

    .required {
        color: #0045FF;
    }

    .document-status {
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 10px;

        div {
            cursor: pointer;
        }
    }

    .form-field {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        p {
            width: 260px;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-align: right;
            color: black;
        }

        .md-field {
            width: 45%;
            max-width: 410px;
            background: #FFFFFF;
            border: 1px solid #CDD0D9;
            box-sizing: border-box;
            border-radius: 4px;
            margin: 0 0 0 16px;
            padding: 0 0 0 24px;

            .md-input {
                height: 48px !important;
            }

            .md-file {
                flex-direction: row-reverse;
            }
        }

        .uploaded {
            background: #DCDCDC;
        }

        .md-field:after, .md-field:before {
            position: unset !important;
        }
    }

    .file-error-msg {
        color: red;
        margin: -16px 0 0 16px;
    }

    .next {
        width: 150px;
        height: 40px;
        margin-left: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        border-radius: 6px;
        background-color: #0045FF;
        color: #FFFFFF;

        img {
            margin-left: 13px;
        }
    }

    .tooltip-wrap {
        margin-left: 15px;
    }
}
</style>

<style lang="scss">
.grant-files {
    .breadcrumb {
        padding: 0 !important;
    }

    .md-file {
        .md-file-icon {
            width: 80px;
            height: 48px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            margin: 0;
            background-color: #0045FF;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                height: 22px;
                fill: #FFFFFF;
                transform: rotate(45deg);
            }
        }

        .md-input {
            margin: 0 !important;
            height: 48px !important;
        }
    }
}
.md-tooltip {
    color: #000000 !important;
    background-color: #FFFFFF !important;
    border: 1px solid #cdd0d9;
    border-radius: 6px;
    padding: 15px;
    //width: 100px !important;
    height: auto !important;
    font-size: 16px;
    line-height: 24px;
}
</style>